import * as ReactApollo from "react-apollo";
import * as React from "react";
import gql from "graphql-tag";

export type Maybe<T> = T | null;

export interface OrderFilterOrder {
  created_date?: Maybe<string>;
}

export interface UsersFilterOrder {
  created_date?: Maybe<string>;
}

export interface PlanRecordFilterOrder {
  created_date?: Maybe<string>;
}

export interface DemandFilterOrder {
  created_date?: Maybe<string>;

  finish_date?: Maybe<string>;
}

export interface TeamFilterOrder {
  created_date?: Maybe<string>;

  name?: Maybe<string>;

  plan_end_date?: Maybe<string>;

  balance?: Maybe<string>;
}

export interface ProjectFilterOrder {
  created_date?: Maybe<string>;
}

export interface MemberFilterOrder {
  created_date?: Maybe<string>;
}

export interface IndependentFilterOrder {
  created_date?: Maybe<string>;
}

export interface PackageFilterOrder {
  created_date?: Maybe<string>;
}

export interface SubscriptionFilterOrder {
  created_date?: Maybe<string>;
}

export interface OrderItemFilterOrder {
  created_date?: Maybe<string>;
}

export interface LoginByAdmin {
  mobile: string;

  password: string;
}

export interface CreateProductOrder {
  product_name: string;

  demand_id: string;
}

export interface CreateSubscriptionOrder {
  subscription_name: string;

  cycle: number;

  price: number;
  /** 月 季度 年 */
  cycle_name: string;
}

export interface UpdateSubscriptionByOrder {
  name: string;

  number: number;

  pay_type: string;
}

export interface SignProductOrder {
  order_id: string;

  pay_type: string;
}

export interface UploadOrderImage {
  pay_uri: string;

  order_id: string;
}

export interface ChangeOrderStatus {
  order_id: string;
}

export interface NotPassOrder {
  reason: string;

  order_id: string;
}

export interface CreatePlanRecord {
  name: string;

  number: number;

  cycle: number;

  pay_type: string;

  cycle_name: string;

  plan_type: string;
}

export interface UpdateSubscription {
  name: string;

  number: number;

  pay_type: string;
}

export interface CreateDemand {
  name: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 视觉风格描述文件 */
  ui_description_files?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 源文件格式要求 */
  original_file_format: string;
  /** 图片格式要求 */
  file_format: string;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
}

export interface ChangeDemandTime {
  id: string;

  finish_date: string;
}

export interface FinishDemand {
  id: string;

  finish_remark: string;
}

export interface SignWeChatInput {
  /** url */
  url: string;
  /** 这个字段特殊 使用大写 */
  jsApiList: string[];
}

export interface CreateTeam {
  name: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AddTeam {
  team_code: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AddBalance {
  team_id: string;

  balance: number;

  remark: string;
}

export interface ChangeTeam {
  team_id: string;
}

export interface CreateProject {
  name: string;
}

export interface CreateMember {
  name: string;
}

export interface CreateIndependent {
  name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;
}

export interface CreatePackage {
  name: string;
}

export interface CreateSubscription {
  name: string;
}

export interface ChangeSubscriptionCount {
  team_id: string;

  count: number;
}

export interface CreateOrderItem {
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface ChangeMobileInput {
  old_mobile: string;

  mobile: string;
  /** 短信验证码 */
  code: string;
  /** 旧手机短信验证码 */
  old_code: string;
}

export interface ChangePasswordInput {
  mobile: string;
  /** 短信验证码 */
  code: string;

  password: string;
}

export interface CreateNoticeInput {
  content: string;

  status: string;
}

export interface CreateNoticeRecordInput {
  title: string;

  content: string;
}

export interface CreateOrder {
  product_name?: Maybe<string>;
}

export interface CreateRoleInput {
  /** 角色名称 admin */
  name: string;
}

export interface CreateRolePermissionInput {
  /** 权限名称 admin */
  name: string;
}

export interface LoginByMobileInput {
  mobile: string;

  password: string;
}

export interface PageArgs {
  before?: Maybe<string>;

  after?: Maybe<string>;

  fullTextSearch?: Maybe<string>;

  first?: Maybe<number>;

  last?: Maybe<number>;

  sort?: Maybe<Sort[]>;
}

export interface Sort {
  field?: Maybe<string>;

  input?: Maybe<string>;
}

export interface RegisterInput {
  mobile: string;
  /** 短信验证码 */
  code: string;

  password: string;
}

export interface UpdatePasswordInput {
  mobile: string;

  code: string;

  password: string;
}

export interface UpdateUserMoreInput {
  sex: string;

  true_name: string;

  email: string;

  we_chat: string;

  birthday: string;
}

export interface VerifyCodeInput {
  mobile: string;

  code: string;
}

/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
export type DateTime = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
  viewer: UserEntity;
  /** 用户管理 */
  users: UserList;

  getOrderById: OrderEntity;
  /** 列表 */
  orders: OrderList;

  getPlanRecordById: PlanRecordEntity;
  /** 列表 */
  planRecords: PlanRecordList;

  getDemandById: DemandEntity;

  getDemandByAdmin: DemandEntity;
  /** 列表 */
  demands: DemandList;

  getFileById: FileEntity;

  requestUploadOSSFile?: Maybe<StsTokenType>;

  getTeamById: TeamEntity;
  /** 创建邀请链接 */
  createTeamUri: TeamUri;
  /** 通过邀请码获取团队 */
  getTeamByCode: BaseTeam;
  /** 列表 */
  teams: TeamList;

  getProjectById: ProjectEntity;
  /** 列表 */
  projects: ProjectList;

  getMemberById: MemberEntity;
  /** 列表 */
  members: MemberList;

  getIndependentById: IndependentEntity;

  getIndependentByAdmin: IndependentEntity;
  /** 列表 */
  independents: IndependentList;

  getPackageById: PackageEntity;
  /** 列表 */
  packages: PackageList;

  getSubscriptionById: SubscriptionEntity;
  /** 列表 */
  subscriptions: SubscriptionList;

  getOrderItemById: OrderItemEntity;
  /** 列表 */
  orderItems: OrderItemList;
}

/** 用户 */
export interface UserEntity {
  id: string;

  username?: Maybe<string>;

  mobile?: Maybe<string>;

  headimgurl?: Maybe<string>;

  proxy_headimgurl?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  demands: DemandEntity[];

  orders: OrderList;

  team?: Maybe<TeamEntity>;

  teams: TeamEntity[];

  is_mobile: boolean;
}

export interface DemandEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 花费的时间 */
  cost_day: number;
  /** 状态 进度 进行中 等待中 已完成 已取消 */
  status: string;
  /** 类别 独立项目 订阅计划 */
  type: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 需求开始时间 */
  start_date?: Maybe<DateTime>;
  /** 需求预计完成时间 */
  estimated_finish_date?: Maybe<DateTime>;
  /** 需求完成时间 */
  finish_date: DateTime;
  /** 源文件格式要求 */
  original_file_format?: Maybe<string>;
  /** 图片格式要求 */
  file_format?: Maybe<string>;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
  /** 完成备注 */
  finish_remark?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  updated_date?: Maybe<DateTime>;

  description_files?: Maybe<FileItemList[]>;

  ui_description_files?: Maybe<FileItemList[]>;

  owner: UserEntity;

  team: TeamEntity;

  project: ProjectEntity;

  order?: Maybe<OrderEntity>;
}

export interface FileItemList {
  uri: string;

  name: string;
}

export interface TeamEntity {
  id: string;

  name: string;

  balance: number;
  /** 订阅名称 */
  plan?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;

  plan_end_date?: Maybe<DateTime>;

  now_plan_end_date?: Maybe<DateTime>;

  plan_count?: Maybe<number>;

  created_date?: Maybe<DateTime>;

  owner: UserEntity;

  members: MemberEntity[];

  subscriptions: SubscriptionEntity[];

  now_subscription?: Maybe<SubscriptionEntity>;

  orders: OrderEntity[];

  order_count: number;
}

export interface MemberEntity {
  id: string;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;
}

export interface SubscriptionEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 数量 */
  count: number;
  /** 订阅开始时间 */
  start_date?: Maybe<DateTime>;
  /** 订阅到期时间 */
  end_date?: Maybe<DateTime>;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price?: Maybe<number>;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;

  created_date?: Maybe<DateTime>;
}

export interface OrderEntity {
  id: string;
  /** 服务类型 单次独立项目 */
  type: string;
  /** 续订 新订 升级 */
  team_type?: Maybe<string>;
  /** 可同时进行的需求数 */
  demand_count?: Maybe<number>;
  /** 明细 */
  detail: string;
  /** 状态 待付款 已付款 待审核 审核通过 审核未通过 */
  status: string;
  /** 付款金额 */
  price: number;
  /** 原价 */
  old_price: number;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 对公账户 */
  pay_type?: Maybe<string>;
  /** 付款时间 */
  pay_date?: Maybe<DateTime>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 原因 */
  reason?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
  /** 付款凭证 */
  pay_uri?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;

  items: OrderItemEntity[];
}

export interface OrderItemEntity {
  id: string;
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface ProjectEntity {
  id: string;
  /** 名称 */
  title: string;
  /** 主图 */
  uri: string;
  /** 类型  或者说是属于哪个套餐 */
  type?: Maybe<string>;
  /** 线上 线下 */
  item_type: string;
  /** 内容 */
  content: string;
  /** 介绍 */
  description: string;
  /** 需要的时间 单位 日 */
  cost_day: number;
}

export interface OrderList {
  count: number;

  list: OrderEntity[];
}

export interface UserList {
  count: number;

  list: UserEntity[];
}

export interface PlanRecordEntity {
  id: string;
  /** 订阅名称 */
  name: string;
  /** 需求数目 */
  number: number;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price: number;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;
  /** 计划类型 */
  plan_type?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
}

export interface PlanRecordList {
  count: number;

  list: PlanRecordEntity[];
}

export interface DemandList {
  count: number;

  list: DemandEntity[];
}

export interface FileEntity {
  id: string;
}

export interface StsTokenType {
  access_key_id: string;

  policy: string;

  signature: string;

  key: string;

  host: string;

  expiration: DateTime;

  dir?: Maybe<string>;

  callback: string;
}

export interface TeamUri {
  uri: string;
}

export interface BaseTeam {
  id: string;

  name: string;

  owner: UserEntity;
}

export interface TeamList {
  count: number;

  list: TeamEntity[];
}

export interface ProjectList {
  count: number;

  list: ProjectEntity[];
}

export interface MemberList {
  count: number;

  list: MemberEntity[];
}

export interface IndependentEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 需求描述 */
  description: string;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;

  created_date?: Maybe<DateTime>;

  description_files?: Maybe<FileItemList[]>;

  owner: UserEntity;

  team: TeamEntity;
}

export interface IndependentList {
  count: number;

  list: IndependentEntity[];
}

export interface PackageEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 现价 */
  price: number;
  /** 得到多少余额 */
  balance: number;
  /** 状态 上架 下架 */
  status: string;
  /** 介绍 */
  introduction: string;
  /** 备注 */
  remark?: Maybe<string>;
}

export interface PackageList {
  count: number;

  list: PackageEntity[];
}

export interface SubscriptionList {
  count: number;

  list: SubscriptionEntity[];
}

export interface OrderItemList {
  count: number;

  list: OrderItemEntity[];
}

export interface Mutation {
  /** 管理员登录 */
  loginByAdmin: AccessToken;
  /** 注册短信验证码 */
  createMobileCode: Result;
  /** 创建独立项目订单 */
  createProductOrder: OrderEntity;
  /** 创建订阅项目订单 */
  createSubscriptionOrder: OrderEntity;
  /** 升级订阅项目订单 */
  updateSubscriptionOrder: OrderEntity;
  /** 订单签名 */
  signProductOrder: OrderEntity;
  /** 上传凭证 */
  uploadPayImage: OrderEntity;
  /** 通过订单 */
  changeOrderStatusByAdmin: OrderEntity;
  /** 不通过订单 */
  notPassOrder: OrderEntity;
  /** 创建 */
  createPlanRecord: PlanRecordEntity;
  /** 升级 */
  updateSubscription: PlanRecordEntity;
  /** 余额支付 */
  balancePay: PlanRecordEntity;
  /** 余额支付更新 */
  balancePayUpdate: PlanRecordEntity;
  /** 创建 */
  createDemand: DemandEntity;
  /** 创建 */
  createDemandByAdmin: DemandEntity;
  /** 修改需求时间 */
  changeDemandTime: DemandEntity;
  /** 完成需求 */
  finishDemand: DemandEntity;
  /** 取消需求 */
  cancelDemand: DemandEntity;
  /** 取消需求 */
  cancelDemandByOwner: DemandEntity;
  /** 置顶需求 */
  topDemandByOwner: DemandEntity;
  /** 转化需求 */
  changeDemandType: DemandEntity;
  /** 微信签名 */
  signWeChat: SignWeChatResult;
  /** 创建 */
  createTeam: TeamEntity;
  /** 加入团队 */
  addTeam: TeamEntity;
  /** 赠送余额 */
  addBalance: TeamEntity;
  /** 切换团队 */
  changeTeam: TeamEntity;
  /** 转移团队管理人 */
  changeTeamOwner: TeamEntity;
  /** 创建 */
  createProject: ProjectEntity;
  /** 创建 */
  createMember: MemberEntity;
  /** 退出团队 */
  outTeam: MemberEntity;
  /** 删除成员 */
  deleteMember: MemberEntity;
  /** 创建 */
  createIndependent: IndependentEntity;
  /** 创建 */
  createPackage: PackageEntity;
  /** 创建 */
  createSubscription: SubscriptionEntity;
  /** 修改同时并发项目的数量 */
  changeSubscriptionCount: SubscriptionEntity;
  /** 创建 */
  createOrderItem: OrderItemEntity;
}

export interface AccessToken {
  user_id?: Maybe<string>;

  expires_in?: Maybe<number>;

  access_token?: Maybe<string>;
}

export interface Result {
  success: boolean;
}

export interface SignWeChatResult {
  debug?: Maybe<boolean>;

  appId?: Maybe<string>;

  timestamp?: Maybe<string>;

  nonceStr?: Maybe<string>;

  signature?: Maybe<string>;

  jsApiList?: Maybe<string[]>;

  mobile?: Maybe<string>;

  true_name?: Maybe<string>;
}

export interface BalanceRecordEntity {
  id: string;
}

export interface Base {
  id: string;
}

/** 渠道 */
export interface ChannelEntity {
  id: string;
  /** 名称 */
  name: string;
}

/** 渠道 */
export interface ChannelRecordEntity {
  id: string;
}

export interface ChartItem {
  key: DateTime;

  value: number;
}

export interface Connection {
  pageInfo?: Maybe<PageInfo>;
}

export interface PageInfo {
  startCursor?: Maybe<string>;

  endCursor?: Maybe<string>;

  hasPreviousPage: boolean;

  hasNextPage: boolean;

  totalCount: boolean;
}

export interface Count {
  count: number;
}

export interface FileList {
  count: number;

  list: FileEntity[];
}

export interface List {
  count: number;
}

/** 通知 📣 */
export interface NoticeEntity {
  id: string;

  content: string;

  status: string;
}

/** 通知记录 📣 */
export interface NoticeRecordEntity {
  id: string;

  title: string;

  content: string;
}

export interface OutResult {
  uri?: Maybe<string>;

  token?: Maybe<string>;
}

export interface OutUriResult {
  uri?: Maybe<string>;
}

/** 推荐记录 🚗 */
export interface ReferrerRecordEntity {
  id: string;
}

/** 角色 */
export interface RoleEntity {
  id: string;
  /** 角色名称 admin */
  name: string;
}

/** 角色 权限 */
export interface RoleItemEntity {
  id: string;
}

/** 权限 */
export interface RolePermissionEntity {
  id: string;
  /** 权限名称 admin */
  name: string;
}

export interface Uri {
  uri: string;
}

// ====================================================
// Arguments
// ====================================================

export interface UsersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;
}
export interface GetOrderByIdQueryArgs {
  id: string;
}
export interface OrdersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface GetPlanRecordByIdQueryArgs {
  id: string;
}
export interface PlanRecordsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PlanRecordFilterOrder>;
}
export interface GetDemandByIdQueryArgs {
  id: string;
}
export interface GetDemandByAdminQueryArgs {
  id: string;
}
export interface DemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;
}
export interface GetFileByIdQueryArgs {
  id: string;
}
export interface RequestUploadOssFileQueryArgs {
  file_name: string;
}
export interface GetTeamByIdQueryArgs {
  id: string;
}
export interface GetTeamByCodeQueryArgs {
  code: string;
}
export interface TeamsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<TeamFilterOrder>;
}
export interface GetProjectByIdQueryArgs {
  id: string;
}
export interface ProjectsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<ProjectFilterOrder>;

  type?: Maybe<string>;
}
export interface GetMemberByIdQueryArgs {
  id: string;
}
export interface MembersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<MemberFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetIndependentByIdQueryArgs {
  id: string;
}
export interface GetIndependentByAdminQueryArgs {
  id: string;
}
export interface IndependentsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<IndependentFilterOrder>;
}
export interface GetPackageByIdQueryArgs {
  id: string;
}
export interface PackagesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PackageFilterOrder>;
}
export interface GetSubscriptionByIdQueryArgs {
  id: string;
}
export interface SubscriptionsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<SubscriptionFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetOrderItemByIdQueryArgs {
  id: string;
}
export interface OrderItemsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderItemFilterOrder>;
}
export interface OrdersUserEntityArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface OrdersTeamEntityArgs {
  skip?: number;

  take?: number;

  search?: Maybe<string>;

  columns?: Maybe<string[]>;
}
export interface LoginByAdminMutationArgs {
  LoginByAdmin: LoginByAdmin;
}
export interface CreateMobileCodeMutationArgs {
  /** 手机号 */
  mobile: string;
  /** 国家区号 */
  country_number: string;
}
export interface CreateProductOrderMutationArgs {
  CreateProductOrder: CreateProductOrder;
}
export interface CreateSubscriptionOrderMutationArgs {
  CreateSubscriptionOrder: CreateSubscriptionOrder;
}
export interface UpdateSubscriptionOrderMutationArgs {
  UpdateSubscriptionByOrder: UpdateSubscriptionByOrder;
}
export interface SignProductOrderMutationArgs {
  SignProductOrder: SignProductOrder;
}
export interface UploadPayImageMutationArgs {
  UploadOrderImage: UploadOrderImage;
}
export interface ChangeOrderStatusByAdminMutationArgs {
  ChangeOrderStatus: ChangeOrderStatus;
}
export interface NotPassOrderMutationArgs {
  NotPassOrder: NotPassOrder;
}
export interface CreatePlanRecordMutationArgs {
  CreatePlanRecord: CreatePlanRecord;
}
export interface UpdateSubscriptionMutationArgs {
  UpdateSubscription: UpdateSubscription;
}
export interface BalancePayMutationArgs {
  plan_record_id: string;
}
export interface BalancePayUpdateMutationArgs {
  plan_record_id: string;
}
export interface CreateDemandMutationArgs {
  CreateDemand: CreateDemand;
}
export interface CreateDemandByAdminMutationArgs {
  team_id: string;

  CreateDemand: CreateDemand;
}
export interface ChangeDemandTimeMutationArgs {
  ChangeDemandTime: ChangeDemandTime;
}
export interface FinishDemandMutationArgs {
  FinishDemand: FinishDemand;
}
export interface CancelDemandMutationArgs {
  id: string;
}
export interface CancelDemandByOwnerMutationArgs {
  id: string;
}
export interface TopDemandByOwnerMutationArgs {
  id: string;
}
export interface ChangeDemandTypeMutationArgs {
  id: string;
}
export interface SignWeChatMutationArgs {
  SignWeChatInput: SignWeChatInput;
}
export interface CreateTeamMutationArgs {
  CreateTeam: CreateTeam;
}
export interface AddTeamMutationArgs {
  AddTeam: AddTeam;
}
export interface AddBalanceMutationArgs {
  AddBalance: AddBalance;
}
export interface ChangeTeamMutationArgs {
  ChangeTeam: ChangeTeam;
}
export interface ChangeTeamOwnerMutationArgs {
  user_id: string;
}
export interface CreateProjectMutationArgs {
  CreateProject: CreateProject;
}
export interface CreateMemberMutationArgs {
  CreateMember: CreateMember;
}
export interface DeleteMemberMutationArgs {
  user_id: string;
}
export interface CreateIndependentMutationArgs {
  CreateIndependent: CreateIndependent;
}
export interface CreatePackageMutationArgs {
  CreatePackage: CreatePackage;
}
export interface CreateSubscriptionMutationArgs {
  CreateSubscription: CreateSubscription;
}
export interface ChangeSubscriptionCountMutationArgs {
  ChangeSubscriptionCount: ChangeSubscriptionCount;
}
export interface CreateOrderItemMutationArgs {
  CreateOrderItem: CreateOrderItem;
}






// ====================================================
// Components
// ====================================================

export const AddTeamDocument = gql`
  mutation AddTeam($AddTeam: AddTeam!) {
    addTeam(AddTeam: $AddTeam) {
      id
    }
  }
`;
export interface AddTeamMutation {
  addTeam: Mutation["addTeam"];
}
export class AddTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AddTeamMutation, AddTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AddTeamMutation, AddTeamMutationArgs>
        mutation={AddTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AddTeamMutation, AddTeamMutationArgs>
> &
  TChildProps;
export type AddTeamMutationFn = ReactApollo.MutationFn<
  AddTeamMutation,
  AddTeamMutationArgs
>;
export function AddTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddTeamMutation,
        AddTeamMutationArgs,
        AddTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddTeamMutation,
    AddTeamMutationArgs,
    AddTeamProps<TChildProps>
  >(AddTeamDocument, operationOptions);
}
export const BalancePayDocument = gql`
  mutation BalancePay($plan_record_id: String!) {
    balancePay(plan_record_id: $plan_record_id) {
      id
    }
  }
`;
export interface BalancePayMutation {
  balancePay: Mutation["balancePay"];
}
export class BalancePayComponent extends React.Component<
  Partial<ReactApollo.MutationProps<BalancePayMutation, BalancePayMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<BalancePayMutation, BalancePayMutationArgs>
        mutation={BalancePayDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BalancePayProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<BalancePayMutation, BalancePayMutationArgs>
> &
  TChildProps;
export type BalancePayMutationFn = ReactApollo.MutationFn<
  BalancePayMutation,
  BalancePayMutationArgs
>;
export function BalancePayHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BalancePayMutation,
        BalancePayMutationArgs,
        BalancePayProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BalancePayMutation,
    BalancePayMutationArgs,
    BalancePayProps<TChildProps>
  >(BalancePayDocument, operationOptions);
}
export const CancelDemandByOwnerDocument = gql`
  mutation CancelDemandByOwner($id: String!) {
    cancelDemandByOwner(id: $id) {
      id
      status
    }
  }
`;
export interface CancelDemandByOwnerMutation {
  cancelDemandByOwner: Mutation["cancelDemandByOwner"];
}
export class CancelDemandByOwnerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CancelDemandByOwnerMutation,
      CancelDemandByOwnerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CancelDemandByOwnerMutation,
        CancelDemandByOwnerMutationArgs
      >
        mutation={CancelDemandByOwnerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CancelDemandByOwnerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CancelDemandByOwnerMutation,
    CancelDemandByOwnerMutationArgs
  >
> &
  TChildProps;
export type CancelDemandByOwnerMutationFn = ReactApollo.MutationFn<
  CancelDemandByOwnerMutation,
  CancelDemandByOwnerMutationArgs
>;
export function CancelDemandByOwnerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CancelDemandByOwnerMutation,
        CancelDemandByOwnerMutationArgs,
        CancelDemandByOwnerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CancelDemandByOwnerMutation,
    CancelDemandByOwnerMutationArgs,
    CancelDemandByOwnerProps<TChildProps>
  >(CancelDemandByOwnerDocument, operationOptions);
}
export const CreateIndependentDocument = gql`
  mutation CreateIndependent($CreateIndependent: CreateIndependent!) {
    createIndependent(CreateIndependent: $CreateIndependent) {
      id
    }
  }
`;
export interface CreateIndependentMutation {
  createIndependent: Mutation["createIndependent"];
}
export class CreateIndependentComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateIndependentMutation,
      CreateIndependentMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateIndependentMutation,
        CreateIndependentMutationArgs
      >
        mutation={CreateIndependentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateIndependentProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateIndependentMutation,
    CreateIndependentMutationArgs
  >
> &
  TChildProps;
export type CreateIndependentMutationFn = ReactApollo.MutationFn<
  CreateIndependentMutation,
  CreateIndependentMutationArgs
>;
export function CreateIndependentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateIndependentMutation,
        CreateIndependentMutationArgs,
        CreateIndependentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateIndependentMutation,
    CreateIndependentMutationArgs,
    CreateIndependentProps<TChildProps>
  >(CreateIndependentDocument, operationOptions);
}
export const CreateMobileCodeDocument = gql`
  mutation CreateMobileCode($mobile: String!, $country_number: String!) {
    createMobileCode(mobile: $mobile, country_number: $country_number) {
      success
    }
  }
`;
export interface CreateMobileCodeMutation {
  createMobileCode: Mutation["createMobileCode"];
}
export class CreateMobileCodeComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateMobileCodeMutation,
      CreateMobileCodeMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateMobileCodeMutation,
        CreateMobileCodeMutationArgs
      >
        mutation={CreateMobileCodeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateMobileCodeProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateMobileCodeMutation,
    CreateMobileCodeMutationArgs
  >
> &
  TChildProps;
export type CreateMobileCodeMutationFn = ReactApollo.MutationFn<
  CreateMobileCodeMutation,
  CreateMobileCodeMutationArgs
>;
export function CreateMobileCodeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateMobileCodeMutation,
        CreateMobileCodeMutationArgs,
        CreateMobileCodeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateMobileCodeMutation,
    CreateMobileCodeMutationArgs,
    CreateMobileCodeProps<TChildProps>
  >(CreateMobileCodeDocument, operationOptions);
}
export const CreatePlanRecordDocument = gql`
  mutation CreatePlanRecord($CreatePlanRecord: CreatePlanRecord!) {
    createPlanRecord(CreatePlanRecord: $CreatePlanRecord) {
      id
      number
      cycle
      price
      out_trade_no
      pay_type
      uri
    }
  }
`;
export interface CreatePlanRecordMutation {
  createPlanRecord: Mutation["createPlanRecord"];
}
export class CreatePlanRecordComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreatePlanRecordMutation,
      CreatePlanRecordMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreatePlanRecordMutation,
        CreatePlanRecordMutationArgs
      >
        mutation={CreatePlanRecordDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreatePlanRecordProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreatePlanRecordMutation,
    CreatePlanRecordMutationArgs
  >
> &
  TChildProps;
export type CreatePlanRecordMutationFn = ReactApollo.MutationFn<
  CreatePlanRecordMutation,
  CreatePlanRecordMutationArgs
>;
export function CreatePlanRecordHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreatePlanRecordMutation,
        CreatePlanRecordMutationArgs,
        CreatePlanRecordProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreatePlanRecordMutation,
    CreatePlanRecordMutationArgs,
    CreatePlanRecordProps<TChildProps>
  >(CreatePlanRecordDocument, operationOptions);
}
export const CreateTeamDocument = gql`
  mutation CreateTeam($CreateTeam: CreateTeam!) {
    createTeam(CreateTeam: $CreateTeam) {
      id
    }
  }
`;
export interface CreateTeamMutation {
  createTeam: Mutation["createTeam"];
}
export class CreateTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateTeamMutation, CreateTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateTeamMutation, CreateTeamMutationArgs>
        mutation={CreateTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateTeamMutation, CreateTeamMutationArgs>
> &
  TChildProps;
export type CreateTeamMutationFn = ReactApollo.MutationFn<
  CreateTeamMutation,
  CreateTeamMutationArgs
>;
export function CreateTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamMutation,
        CreateTeamMutationArgs,
        CreateTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamMutation,
    CreateTeamMutationArgs,
    CreateTeamProps<TChildProps>
  >(CreateTeamDocument, operationOptions);
}
export const CreateTeamUriDocument = gql`
  query CreateTeamUri {
    createTeamUri {
      uri
    }
  }
`;
export interface CreateTeamUriQuery {
  createTeamUri: Query["createTeamUri"];
}
export class CreateTeamUriComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CreateTeamUriQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<CreateTeamUriQuery, any>
        query={CreateTeamUriDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamUriProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CreateTeamUriQuery, any>
> &
  TChildProps;
export function CreateTeamUriHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamUriQuery,
        any,
        CreateTeamUriProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamUriQuery,
    any,
    CreateTeamUriProps<TChildProps>
  >(CreateTeamUriDocument, operationOptions);
}
export const DeleteMemberDocument = gql`
  mutation DeleteMember($user_id: String!) {
    deleteMember(user_id: $user_id) {
      id
    }
  }
`;
export interface DeleteMemberMutation {
  deleteMember: Mutation["deleteMember"];
}
export class DeleteMemberComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteMemberMutation, DeleteMemberMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteMemberMutation, DeleteMemberMutationArgs>
        mutation={DeleteMemberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteMemberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteMemberMutation, DeleteMemberMutationArgs>
> &
  TChildProps;
export type DeleteMemberMutationFn = ReactApollo.MutationFn<
  DeleteMemberMutation,
  DeleteMemberMutationArgs
>;
export function DeleteMemberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteMemberMutation,
        DeleteMemberMutationArgs,
        DeleteMemberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteMemberMutation,
    DeleteMemberMutationArgs,
    DeleteMemberProps<TChildProps>
  >(DeleteMemberDocument, operationOptions);
}
export const GetOrderByIdDocument = gql`
  query GetOrderById($id: String!) {
    getOrderById(id: $id) {
      id
      status
    }
  }
`;
export interface GetOrderByIdQuery {
  getOrderById: Query["getOrderById"];
}
export class GetOrderByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetOrderByIdQuery, GetOrderByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetOrderByIdQuery, GetOrderByIdQueryArgs>
        query={GetOrderByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetOrderByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetOrderByIdQuery, GetOrderByIdQueryArgs>
> &
  TChildProps;
export function GetOrderByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetOrderByIdQuery,
        GetOrderByIdQueryArgs,
        GetOrderByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetOrderByIdQuery,
    GetOrderByIdQueryArgs,
    GetOrderByIdProps<TChildProps>
  >(GetOrderByIdDocument, operationOptions);
}
export const GetPlanRecordByIdDocument = gql`
  query GetPlanRecordById($id: String!) {
    getPlanRecordById(id: $id) {
      id
      status
    }
  }
`;
export interface GetPlanRecordByIdQuery {
  getPlanRecordById: Query["getPlanRecordById"];
}
export class GetPlanRecordByIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
        query={GetPlanRecordByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetPlanRecordByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
> &
  TChildProps;
export function GetPlanRecordByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetPlanRecordByIdQuery,
        GetPlanRecordByIdQueryArgs,
        GetPlanRecordByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetPlanRecordByIdQuery,
    GetPlanRecordByIdQueryArgs,
    GetPlanRecordByIdProps<TChildProps>
  >(GetPlanRecordByIdDocument, operationOptions);
}
export const GetTeamByCodeDocument = gql`
  query GetTeamByCode($code: String!) {
    getTeamByCode(code: $code) {
      id
      name
      owner {
        id
        username
        headimgurl
      }
    }
  }
`;
export interface GetTeamByCodeQuery {
  getTeamByCode: Query["getTeamByCode"];
}
export class GetTeamByCodeComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>
        query={GetTeamByCodeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetTeamByCodeProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>
> &
  TChildProps;
export function GetTeamByCodeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetTeamByCodeQuery,
        GetTeamByCodeQueryArgs,
        GetTeamByCodeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetTeamByCodeQuery,
    GetTeamByCodeQueryArgs,
    GetTeamByCodeProps<TChildProps>
  >(GetTeamByCodeDocument, operationOptions);
}
export const OutTeamDocument = gql`
  mutation OutTeam {
    outTeam {
      id
    }
  }
`;
export interface OutTeamMutation {
  outTeam: Mutation["outTeam"];
}
export class OutTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<OutTeamMutation, any>>
> {
  render() {
    return (
      <ReactApollo.Mutation<OutTeamMutation, any>
        mutation={OutTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<OutTeamMutation, any>
> &
  TChildProps;
export type OutTeamMutationFn = ReactApollo.MutationFn<
  OutTeamMutation,
  any
>;
export function OutTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutTeamMutation,
        any,
        OutTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutTeamMutation,
    any,
    OutTeamProps<TChildProps>
  >(OutTeamDocument, operationOptions);
}
export const PackagesDocument = gql`
  query Packages($take: Int) {
    packages(take: $take, order: { created_date: "ASC" }) {
      count
      list {
        id
        name
        price
        balance
        status
        introduction
      }
    }
  }
`;
export interface PackagesQuery {
  packages: Query["packages"];
}
export class PackagesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<PackagesQuery, PackagesQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<PackagesQuery, PackagesQueryArgs>
        query={PackagesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PackagesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<PackagesQuery, PackagesQueryArgs>
> &
  TChildProps;
export function PackagesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PackagesQuery,
        PackagesQueryArgs,
        PackagesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PackagesQuery,
    PackagesQueryArgs,
    PackagesProps<TChildProps>
  >(PackagesDocument, operationOptions);
}
export const ProjectsDocument = gql`
  query Projects($take: Int, $type: String) {
    projects(take: $take, type: $type) {
      count
      list {
        id
        title
        uri
        type
        content
        description
        cost_day
        item_type
      }
    }
  }
`;
export interface ProjectsQuery {
  projects: Query["projects"];
}
export class ProjectsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ProjectsQuery, ProjectsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<ProjectsQuery, ProjectsQueryArgs>
        query={ProjectsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ProjectsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ProjectsQuery, ProjectsQueryArgs>
> &
  TChildProps;
export function ProjectsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ProjectsQuery,
        ProjectsQueryArgs,
        ProjectsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ProjectsQuery,
    ProjectsQueryArgs,
    ProjectsProps<TChildProps>
  >(ProjectsDocument, operationOptions);
}
export const RequestUploadOssFileDocument = gql`
  query RequestUploadOSSFile($file_name: String!) {
    requestUploadOSSFile(file_name: $file_name) {
      access_key_id
      policy
      signature
      key
      host
      expiration
      dir
      callback
    }
  }
`;
export interface RequestUploadOssFileQuery {
  requestUploadOSSFile: Query["requestUploadOSSFile"];
}
export class RequestUploadOssFileComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RequestUploadOssFileQuery,
      RequestUploadOssFileQueryArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs
      >
        query={RequestUploadOssFileDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RequestUploadOssFileProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs
  >
> &
  TChildProps;
export function RequestUploadOssFileHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs,
        RequestUploadOssFileProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs,
    RequestUploadOssFileProps<TChildProps>
  >(RequestUploadOssFileDocument, operationOptions);
}
export const SignWeChatDocument = gql`
  mutation SignWeChat($SignWeChatInput: SignWeChatInput!) {
    signWeChat(SignWeChatInput: $SignWeChatInput) {
      debug
      appId
      timestamp
      nonceStr
      signature
      jsApiList
    }
  }
`;
export interface SignWeChatMutation {
  signWeChat: Mutation["signWeChat"];
}
export class SignWeChatComponent extends React.Component<
  Partial<ReactApollo.MutationProps<SignWeChatMutation, SignWeChatMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<SignWeChatMutation, SignWeChatMutationArgs>
        mutation={SignWeChatDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SignWeChatProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SignWeChatMutation, SignWeChatMutationArgs>
> &
  TChildProps;
export type SignWeChatMutationFn = ReactApollo.MutationFn<
  SignWeChatMutation,
  SignWeChatMutationArgs
>;
export function SignWeChatHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SignWeChatMutation,
        SignWeChatMutationArgs,
        SignWeChatProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SignWeChatMutation,
    SignWeChatMutationArgs,
    SignWeChatProps<TChildProps>
  >(SignWeChatDocument, operationOptions);
}
export const TopDemandByOwnerDocument = gql`
  mutation TopDemandByOwner($id: String!) {
    topDemandByOwner(id: $id) {
      id
      status
    }
  }
`;
export interface TopDemandByOwnerMutation {
  topDemandByOwner: Mutation["topDemandByOwner"];
}
export class TopDemandByOwnerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      TopDemandByOwnerMutation,
      TopDemandByOwnerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        TopDemandByOwnerMutation,
        TopDemandByOwnerMutationArgs
      >
        mutation={TopDemandByOwnerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TopDemandByOwnerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    TopDemandByOwnerMutation,
    TopDemandByOwnerMutationArgs
  >
> &
  TChildProps;
export type TopDemandByOwnerMutationFn = ReactApollo.MutationFn<
  TopDemandByOwnerMutation,
  TopDemandByOwnerMutationArgs
>;
export function TopDemandByOwnerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TopDemandByOwnerMutation,
        TopDemandByOwnerMutationArgs,
        TopDemandByOwnerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TopDemandByOwnerMutation,
    TopDemandByOwnerMutationArgs,
    TopDemandByOwnerProps<TChildProps>
  >(TopDemandByOwnerDocument, operationOptions);
}
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
      username
      headimgurl
      is_mobile
      team {
        id
        name
        balance
        owner {
          id
        }
      }
    }
  }
`;
export interface ViewerQuery {
  viewer: Query["viewer"];
}
export class ViewerComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerQuery, any>
        query={ViewerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerQuery, any>
> &
  TChildProps;
export function ViewerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerQuery,
        any,
        ViewerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerQuery,
    any,
    ViewerProps<TChildProps>
  >(ViewerDocument, operationOptions);
}
export const ViewerDemandsDocument = gql`
  query ViewerDemands {
    viewer {
      id
      demands {
        id
        name
        status
        finish_date
        created_date
        owner {
          id
          username
          mobile
          headimgurl
        }
        project {
          id
          title
        }
      }
    }
  }
`;
export interface ViewerDemandsQuery {
  viewer: Query["viewer"];
}
export class ViewerDemandsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerDemandsQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerDemandsQuery, any>
        query={ViewerDemandsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerDemandsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerDemandsQuery, any>
> &
  TChildProps;
export function ViewerDemandsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerDemandsQuery,
        any,
        ViewerDemandsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerDemandsQuery,
    any,
    ViewerDemandsProps<TChildProps>
  >(ViewerDemandsDocument, operationOptions);
}
export const ViewerSubscriptionsDocument = gql`
  query ViewerSubscriptions {
    viewer {
      id
      team {
        id
        subscriptions {
          id
          name
          count
          end_date
          cycle_name
          start_date
        }
      }
    }
  }
`;
export interface ViewerSubscriptionsQuery {
  viewer: Query["viewer"];
}
export class ViewerSubscriptionsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      ViewerSubscriptionsQuery,
      any
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<ViewerSubscriptionsQuery, any>
        query={ViewerSubscriptionsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerSubscriptionsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerSubscriptionsQuery, any>
> &
  TChildProps;
export function ViewerSubscriptionsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerSubscriptionsQuery,
        any,
        ViewerSubscriptionsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerSubscriptionsQuery,
    any,
    ViewerSubscriptionsProps<TChildProps>
  >(ViewerSubscriptionsDocument, operationOptions);
}
export const ViewerTeamDocument = gql`
  query ViewerTeam {
    viewer {
      id
      team {
        id
        name
        balance
        owner {
          id
        }
        members {
          id
          created_date
          owner {
            id
            username
            mobile
            headimgurl
          }
        }
        subscriptions {
          id
          name
          count
          end_date
          cycle_name
          start_date
        }
      }
    }
  }
`;
export interface ViewerTeamQuery {
  viewer: Query["viewer"];
}
export class ViewerTeamComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerTeamQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerTeamQuery, any>
        query={ViewerTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerTeamProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerTeamQuery, any>
> &
  TChildProps;
export function ViewerTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerTeamQuery,
        any,
        ViewerTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerTeamQuery,
    any,
    ViewerTeamProps<TChildProps>
  >(ViewerTeamDocument, operationOptions);
}
