import React from 'react';
import { Button } from 'antd';
import queryString from 'query-string';

import styles from './index.module.css';
import {
  GetTeamByCodeComponent,
  ViewerComponent,
  AddTeamComponent,
} from '../../apollo';
import group from './group@2x.png';
import first from './first@2x.png';
import second from './second@2x.png';
import { message } from '../../components/message';

export default class AddTeam extends React.Component<any> {
  componentDidMount() {
    const code: any = queryString.parse(window.location.search).code;
    if (code) {
      localStorage.setItem(
        'team_code',
        JSON.stringify({
          created_date: new Date(),
          code,
        }),
      );
    }
  }
  
  state = {};

  render() {
    const { code, share }: any = queryString.parse(window.location.search);
    if (share) {
      // window.history.pushState(null, "邀请加入", window.location.pathname.replace('&share=true', ''));
      return (
        <div className={'golden_container'}>
          <div className={'golden_content'}>
            <div className={`${styles.hello} ${styles.golden}`}>Nef.Design</div>
            <div className={styles.share_top}>邀请微信好友加入</div>
            <img className={styles.share_img} src={first} alt="占位符" />
            <div className={styles.share_title}>第一步</div>
            <div className={styles.share_desc}>点击右上角「…」</div>
            <img className={styles.share_img} src={second} alt="占位符" />
            <div className={styles.share_title}>第二步</div>
            <div className={styles.share_desc}>发送给受邀人</div>
          </div>
        </div>
      );
    }
    return (
      <GetTeamByCodeComponent variables={{ code }}>
        {({ error, loading, data }) => {
          if (loading) {
            return '';
          }
          if (error || !data) {
            return (
              <div className={styles.warn_container}>
                <img className={styles.warn_img} src={group} alt="" />
                <p className={styles.warn_title}>邀请链接已失效</p>
                <p className={styles.warn_desc}>
                  请联系团队管理员重新生成邀请链接
                </p>
              </div>
            );
          }
          return (
            <ViewerComponent>
              {({ data: viewer_data, error, loading }) => {
                if (loading || !viewer_data) {
                  return '';
                }
                const viewer = viewer_data.viewer;
                return (
                  <div className={'golden_container'}>
                    <div className={'golden_content'}>
                      <div className={`${styles.hello} ${styles.golden}`}>
                        Nef.Design
                      </div>
                      <div className={styles.paragraph}>邀请函</div>
                      <div className={styles.invite}>
                        <img
                          className="header_normal_img"
                          src={data.getTeamByCode.owner.headimgurl || ''}
                          style={{ marginRight: '8px' }}
                          alt=""
                        />
                        {data.getTeamByCode.owner.username}邀请您加入
                      </div>
                      <div className={styles.big_paragraph}>
                        {data.getTeamByCode.name}
                      </div>
                      <div className={styles.bottom}>
                        <div className={styles.bottom_title}>
                          加入团队后将能够
                        </div>
                        <div className={styles.bottom_list_item}>
                          <span className={styles.bottom_point} />
                          查看、使用当前团队的余额
                        </div>
                        <div className={styles.bottom_list_item}>
                          <span className={styles.bottom_point} />
                          查看、管理当前团队的所有需求
                        </div>
                        <div className={styles.bottom_list_item}>
                          <span className={styles.bottom_point} />
                          确认项目的需求预算、明细
                        </div>
                      </div>
                      <AddTeamComponent>
                        {(send, { loading }) => {
                          return (
                            <Button
                              loading={loading}
                              className={`linear-gradient-golden ${styles.sure} normal_button`}
                              onClick={async () => {
                                // this.props.history.push(
                                //   `/login?team_id=${data.getTeamByCode.id}&code=${code}`,
                                // )
                                const code: any = queryString.parse(
                                  window.location.search,
                                ).code;
                                if (viewer.is_mobile) {
                                  try {
                                    await send({
                                      variables: {
                                        AddTeam: { team_code: code },
                                      },
                                    });
                                  } catch (e) {
                                    return message.error(e);
                                  }
                                  return this.props.history.push('/');
                                }
                                if (code) {
                                  localStorage.setItem(
                                    'team_code',
                                    JSON.stringify({
                                      created_date: new Date(),
                                      code,
                                    }),
                                  );
                                }
                                this.props.history.push(
                                  `/add-team-form?team_id=${data.getTeamByCode.id}&code=${code}`,
                                );
                                // window.location.href = server_uri + '/api/we-chat/login';
                              }}
                              type="primary"
                            >
                              确认加入
                            </Button>
                          );
                        }}
                      </AddTeamComponent>
                    </div>
                  </div>
                );
              }}
            </ViewerComponent>
          );
        }}
      </GetTeamByCodeComponent>
    );
  }
}
