import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { graphql_uri } from '../config';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: graphql_uri,
  credentials: 'include', // 跨域设置
});

const authLink = setContext((_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // const token =
    // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIwNTkwNzVkLTg4MWUtNDg2NS05YTY0LTkzNWI1YTkzMTIzOSIsImlhdCI6MTU3NjU2MjIzOSwiZXhwIjoxNTc2OTIyMjM5fQ.OTAoxHClE2ImpsGFoR_EuJByPy35r1gIEHxUHxO4e5o';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  // link: ApolloLink.from([errorLink, httpLink, authLink]),
  link: authLink.concat(ApolloLink.from([errorLink, httpLink])),
  cache: new InMemoryCache(),
});

export default client;
