import React from 'react';

import styles from './index.module.css';
import { ViewerComponent } from '../../apollo';

export default class Index extends React.Component<any> {
  state = {};

  render() {
    return (
      <ViewerComponent>
        {({ error, loading, data }) => {
          if (loading || error || !data) {
            return '';
          }
          return (
            <div className={'golden_container'}>
              <div className={'golden_content'}>
                <img
                  className={`header_normal_img ${styles.img}`}
                  src={data.viewer.headimgurl || ''}
                  alt=""
                />
                <br />
                <span className={styles.username}>{data.viewer.username}</span>
                <div className={styles.tip_1}>
                  请使用电脑登录云设计部平台体验完整功能
                </div>
                <div className={styles.tip_2}>https://yun.nef.design</div>
                <div className={styles.bottom}>
                  <div className={styles.bottom_title}>
                    在PC端成功登录后您可以
                  </div>
                  <div className={styles.bottom_list_item}>
                    <span className={styles.bottom_point} />
                    提交新的设计需求
                  </div>
                  <div className={styles.bottom_list_item}>
                    <span className={styles.bottom_point} />
                    调整已有需求的排期
                  </div>
                  <div className={styles.bottom_list_item}>
                    <span className={styles.bottom_point} />
                    查收各类交付物
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </ViewerComponent>
    );
  }
}
