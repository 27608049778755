import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { PrivateRoute } from './private-route';
import { LocationListener } from './LocationListener';
import Index from '../pages/Index';
import { AddTeamForm } from '../pages/add-team-form';
import AddTeam from '../pages/add-team';

class Routers extends Component {
  render() {
    return (
      <Router>
        <LocationListener>
          {/* <LocationListener> */}
          {/* <Route exact path="/" component={Index} /> */}
          <PrivateRoute exact path="/add-team" component={AddTeam} />
          <PrivateRoute exact path="/add-team-form/:code" component={AddTeamForm} />
          <PrivateRoute exact path="/add-team-form" component={AddTeamForm} />
          <PrivateRoute exact path="/" component={Index} />
          {/* </LocationListener> */}
        </LocationListener>
      </Router>
    );
  }
}

export default Routers;
