import { Component } from 'react';
import { SignWeChatDocument } from '../apollo';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';

const jsApiList = [
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  // 'onMenuShareQQ',
  // 'onMenuShareWeibo',
  // 'onMenuShareQZone',
];

class LocationListenerComponent extends Component<any> {
  unListen: any;
  componentDidMount() {
    // var u = navigator.userAgent,
    //   app = navigator.appVersion;
    // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (isAndroid) {
    //   alert("安卓")
    //   //这个是安卓操作系统
    //   this.configWeChat();
    // }
    // if (isIOS) {
    //   alert("ios")
    //这个是ios操作系统
    // this.handleLocationChange(this.props.history.location);
    // this.unListen = this.props.history.listen(this.handleLocationChange);
    // }

    this.handleLocationChange(this.props.history.location);
    this.unListen = this.props.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    this.unListen();
  }

  configWeChat = async () => {
    try {
      const { data } = await this.props.client.mutate({
        mutation: SignWeChatDocument,
        variables: {
          SignWeChatInput: {
            url: window.location.href,
            jsApiList,
          },
        },
        fetchPolicy: 'no-cache',
        cachePolicy: { query: false, data: false },
      });
      const wx = (window as any).wx;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.signWeChat.appId, // 必填，公众号的唯一标识
        timestamp: data.signWeChat.timestamp, // 必填，生成签名的时间戳 见2.4
        nonceStr: data.signWeChat.nonceStr, // 必填，生成签名的随机串 见2.4
        signature: data.signWeChat.signature, // 必填，签名，见 2.3
        jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见 2.5
      });
      wx.ready(() => {
        let config = {
          title: '云设计部', // 分享标题
          desc:
            '欢迎加入云设计部',
          link: window.location.href, // 分享链接
          // imgUrl: sign_up_uri, // 分享图标
          // success: function() {
          //   alert('成功');
          // },
          // cancel: function() {
          //   alert('失败');
          // },
        };
        switch (this.props.location.pathname) {
          case '/add-team': {
            config = {
              title: '云设计部', // 分享标题
              desc:
                '欢迎加入云设计部',
              link: window.location.href.replace('&share=true', ''), // 分享链接
              // imgUrl: research_uri, // 分享图标
            };
            break;
          }
          default: {
            break;
          }
        }
        wx.onMenuShareTimeline(config);
        wx.onMenuShareAppMessage(config);
      });
      // console.log(data);
      // console.log(`- - - location: '${location.pathname}'`, location, window.location.href);
    } catch (e) {
      console.log(e);
    }
  };

  handleLocationChange = async (location: Location) => {
    // alert(JSON.stringify(location));
    // alert(JSON.stringify(window.location));
    // your staff here
    this.configWeChat();
  };

  render() {
    return this.props.children;
  }
}

export const LocationListener = withRouter(withApollo(LocationListenerComponent));