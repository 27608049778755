import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ViewerComponent } from '../apollo';
import queryString from 'query-string';
import { server_uri } from '../config';

// const time = 1000 * 60 * 60 * 24; // 最大登录有效时间为 24 小时
export class PrivateRoute extends Component<any, {}> {
  auth = {
    status: false,
    updated_date: Date.now(),
  };
  render() {
    const { component: TrueComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          const path = this.props.path;
          if (path === '/') {
            const search = queryString.parse(window.location.search);
            if (search.access_token) {
              localStorage.setItem('token', String(search.access_token));
              // window.history.pushState(null, '首页', '/');
              const href = localStorage.getItem('href');
              console.log("get href", href)
              if (href) {
                window.location.href = href;
                localStorage.removeItem('href');
                return '跳转中';
              }
              window.history.pushState(null, '首页', '/');
            }
          }

          // if (this.auth.status && Date.now() - this.auth.updated_date < time) {
          //   return (
          //     <Route
          //       {...this.props}
          //       render={props => <TrueComponent {...props} />}
          //     />
          //   );
          // }
          return (
            <ViewerComponent fetchPolicy="no-cache">
              {({ data, error, loading }) => {
                if (loading) {
                  return '';
                }

                // fix 如果是未登录错误 返回登录
                // 如果是其他的错误返回 500 页面
                // 如果是网络错误返回到网络错误页面
                if (error || !data) {
                  // return (
                  //   <Redirect
                  //     exact
                  //     to={{
                  //       pathname: '/login',
                  //       state: { from: this.props.location },
                  //     }}
                  //   />
                  // );
                  localStorage.setItem('href', window.location.href);
                  console.log("set href", window.location.href)
                  window.location.href = server_uri + '/api/we-chat/login';
                  return "登录中..."
                }
                this.auth = {
                  status: true,
                  updated_date: Date.now(),
                };

                const code = localStorage.getItem('team_code');
                let result: any = {};
                if (code) {
                  result = JSON.parse(code);
                }
                if (
                  !data.viewer.team &&
                  code &&
                  Date.now() - new Date(result.created_date).getTime() <
                  1000 * 60 * 2 &&
                  path !== '/add-team-form'
                ) {
                  localStorage.removeItem('team_code')
                  return (
                    <Redirect
                      exact={false}
                      to={{
                        // pathname: `/add-team-form`,
                        pathname: `/add-team-form/${result.code}`,
                        // pathname: `/add-team-form?team_code=${result.code}`,
                        // state: { from: this.props.location },
                      }}
                    />
                  );
                  // window.location.href = `/add-team-form?team_code=${result.code}`;
                }
                // if (!data.viewer.team && path !== '/create-team') {
                //   return (
                //     <Redirect
                //       exact
                //       to={{
                //         pathname: '/create-team',
                //         state: { from: this.props.location },
                //       }}
                //     />
                //   );
                // }
                return (
                  <Route
                    {...this.props}
                    render={props => <TrueComponent {...props} />}
                  />
                );
              }}
            </ViewerComponent>
          );
        }}
      />
    );
  }
}
